<template>
   <div class="record-list-wrapper">
       <div class="flex-box-column record-list-cover">
           <div class="hd">
               <van-nav-bar 
               left-arrow 
               safe-area-inset-top 
               right-text="搜索" 
               @click-right="onSearch" 
               class="search" 
               @click-left="back">
                <template #title>
                        <van-search v-model="keyword" class="sm border" shape="round" placeholder="请输入关键字搜索订单" @search="onSearch" ref="recordListSearch"/>
                    </template>
                </van-nav-bar>
                <div class="menu border_bottom z-index-10">
                    <van-dropdown-menu class="z-index-10" :close-on-click-outside="false" ref="recordListMenu">
                        <van-dropdown-item v-model="flowPathValue" @change="flowPathChange" :options="recordStatusOptions" :title="flowPathTitle"/>
                        <van-dropdown-item ref="record_date" :title="recordTimeTitle">
                            <van-cell center :title="item.text" @click="selectDate(item)" v-for="(item,index) in option2" :title-class="{active:recordTimeIndex == index}" :key="index"/>
                            <div class="date-interval">
                                <div class="date-interval-item" @click="calendarOpen">{{recordStartTime ? recordStartTime :'开始时间'}}</div>
                                <div class="g">一</div>
                                <div class="date-interval-item" @click="calendarOpen">{{recordEndTime ? recordEndTime :'结束时间'}}</div>
                                <button 
                                class="confirm date-interval-item" 
                                @click="selectDate({text:'自选时间',value:99})"
                                :class="{live:recordStartTime && recordEndTime}"
                                >确定</button>
                            </div>
                        </van-dropdown-item>
                        <van-dropdown-item :title="areaTitle" ref="listCityMenu">
                            <van-tree-select
                            :items="cityList"
                            :active-id.sync="areaId"
                            :main-active-index.sync="areaIndex"
                            @click-item="selectCity"
                            @click-nav="selectCityNav"
                            />
                        </van-dropdown-item>  
                    </van-dropdown-menu>
                </div>
           </div>
           <div class="bd" ref="recordListScrollView">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :disabled="listError && recordList.length == 0">
                    <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="100"
                    :error.sync="listError"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                    >
                        <div class="record-item border_bottom_top" :class="{'m-selected':!!selectedItemObj[item.id]}" @click="linkTo(item)" v-for="(item,index) in recordList" :key="index">
                            <div class="record-item-multiple-overly" v-if="multiple"></div>
                            <div class="record-item-cover">
                                <div class="record-item-l avatar" :class="{man:item.customerInfo.sex == 1 || !item.sex,woman:item.customerInfo.sex==2}"></div>
                                <div class="record-item-r">
                                    <div class="info border_bottom">
                                        <div class="customer">
                                            <div class="name">{{item.customerInfo.name}}<span class="phone">{{item.customerInfo.phone}}</span></div>
                                            <!-- 时间判断 是否为报备无效以及退房 -->
                                            <div class="record-time" :class="{close:item.approvalStatus == 'RECORDCLOSE' || item.approvalStatus == 'MAKEBARGAINCLOSE'}">
                                                {{
                                                    item.approvalStatus == 'RECORDSTART'
                                                    
                                                    ? item.createdAt 

                                                    : item.approvalStatus == 'RECORDSTANDBY' ? item.auditAt : item.operateAt
                                                }}
                                            </div>
                                        </div>
                                        <div class="house-name">{{item.houseName}}</div>
                                        <div class="visit-date" v-if="item.approvalStatus == 'VISIT'"><div class="color-999">到访时间：{{item.visitTime}}</div></div>
                                        <div class="staff-info">
                                            <span>经纪人：{{item.subdistributorUserName}}</span><a class="tel" :href="'tel:'+item.subdistributorUserPhone" v-if="item.subdistributorUserPhone" @click.stop>{{item.subdistributorUserPhone}}<van-icon name="phone" class="call-icon"></van-icon></a>
                                        </div>
                                        <div class="staff-info">
                                            <span>带看人：{{item.dkName || item.subdistributorUserName}}</span>
                                            <a 
                                                class="tel" 
                                                :href="'tel:'+ item.dkPhone ||  item.subdistributorUserPhone"  
                                                @click.stop>{{item.dkPhone || item.subdistributorUserPhone}}<van-icon name="phone" class="call-icon"></van-icon>
                                            </a>
                                        </div>   
                                        <div class="label-item">
                                            <span>分销公司：</span>
                                            <span>{{item.subdistributorName}}</span>
                                        </div>   
                                        <div class="label-item">
                                            <span>预计到访时间：</span>
                                            <span>{{item.estimatedVisitTime}}</span>
                                        </div>           
                                        <div class="label-item" v-if="item.reception">
                                            <span>指定接待：</span>
                                            <span>{{item.reception}}</span>
                                        </div>
                                    </div>
                                    <div class="des">
                                        <div class="record-type" v-if="item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE'">
                                            <div class="record-type-status">报备
                                                <span class="success" v-if="item.approvalStatus == 'RECORDCOMPLETE'">有效</span>
                                                <span class="faild" v-if="item.approvalStatus == 'RECORDCLOSE'">无效</span>
                                            </div>
                                            <div class="button-cover" v-if="item.approvalStatus == 'RECORDCOMPLETE'">       
                                                <button 
                                                class="record-again border-all"
                                                @click.stop="submitRecordData('UNVISIT','未到访',item)"
                                                >未到访</button>
                                                <button 
                                                class="record-copy border-all"
                                                @click.stop="submitRecordData('VISIT','已到访',item)"
                                                >已到访</button>
                                            </div>
                                        </div>
                                        <div class="record-type" v-else-if="item.approvalStatus == 'RECORDSTART'">
                                            <div class="record-type-status await">待处理</div>
                                        </div>    
                                        <div class="record-type" v-else-if="item.approvalStatus == 'RECORDSTANDBY'">
                                            <div class="record-type-status await">处理中</div>
                                        </div>
                                        <div class="record-type" v-else>
                                            <div class="record-type-status">
                                                <span :class="{success:item.approvalStatus=='VISIT' || item.approvalStatus == 'MAKEBARGAIN' || item.approvalStatus =='MAKEBARGAINCOMPLETE',faild:item.approvalStatus == 'MAKEBARGAINCLOSE',warning:item.approvalStatus=='UNVISIT'}">{{getApprovalStatusText(item.approvalStatus)}}</span>
                                            </div>
                                        </div>
                                        <div class="record-extra">
                                            <p v-if="item.approvalStatus == 'RECORDCLOSE' && item.approvalResult" class="recordFailed">
                                                <span>审核意见：</span>
                                                <span>{{item.approvalResult}}</span>
                                            </p>      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
           </div>
            <!-- 区间弹窗 -->
            <van-calendar 
            v-model="calendarShow" 
            type="range"
            @confirm="selectDateInterval" 
            color="#00a0e4"
            :min-date="minDate" 
            :max-date="maxDate" />
            <van-popup 
            v-model="showDatePicker" 
            safe-area-inset-bottom
            close-on-popstate 
            position="bottom"
            allow-same-day
            >
                <van-datetime-picker
                    v-model="currentTime"
                    type="datetime"
                    title="选择到访时间"
                    :min-date="recordMinDate"
                    :max-date="recordMaxDate"
                    @cancel="closeDatePicker"
                    @confirm="selectVisitDate"
                    :formatter="formatter"
                />
            </van-popup>
       </div>
   </div>
</template>

<script>

import api from "@/api";

import { getDateFky,dateChange,pickerFormatter,findIndex,isObjectNone} from "@/utils";

import back from "@/mixins/back";

import getStatusTextMixin from "@/mixins/getStatusText";

import recordListMultiple from "@/mixins/recordListMultiple"

export default {

    mixins:[back,getStatusTextMixin,recordListMultiple],

    computed:{


        getStaffList(){

            return this.staffList || [{text:'不限',value:0}];

        },

        //报备状态列表

        recordStatusList(){

            return this.$store.state.userInfo.recordStatusList || [{text:'不限',value:0}]

        },

        //公司主体

        companyName(){

            return process.env?.VUE_APP_SYSTEM_NAME == 'huimai' ? '海南慧麦房地产营销策划有限公司' : process.env?.VUE_APP_SYSTEM_NAME == 'fky' ? '海南房小云网络科技有限公司' : '暂无'

        },

    },

    data(){

        return{

            cityList:[],

            currentTime:new Date(),

            showDatePicker:false,

            keyword:'',

            recordList:[],

            staffList:[],

            option2: [

                { text: '不限', value: 0 },

                { text: '本日', value: 1 },

                { text: '本周', value: 2 },

                { text: '本月', value: 4 },

                { text: '近3个月', value: 6 },

                { text: '近6个月', value: 7 },

            ],

            visitForm:{},

            //是否到底

            finished:false,

            //是否加载

            loading:false,

            //是否下拉

            refreshing:false,

            //是否出错

            listError:false,

            //页码

            page:1,

            //一页请求的数据

            size:8,

            //日历展示

            calendarShow:false,


            //流程

            flowPathTitle:'流程',

            flowPathValue:0,

            //报备时间

            recordStartTime:'',

            recordEndTime:'',

            recordTimeIndex:0,

            recordTimeTitle:'时间',

            recordTimeObj:{startDate:'',endDate:''},

            minDate: new Date(2020, 0, 1),

            maxDate: new Date(),
            
            //当前选中的区域index

            areaIndex: 0,

            //当前选中的区域id

            areaId:0,

            oldAreaId:0,

            areaTitle:'区域',
            
            recordStatusOptions:[],

            scrollTop:0,

            recordMinDate: new Date(2020, 0, 1),

            recordMaxDate: new Date(2028, 10, 1),


        }

    },


    methods:{

        closeDatePicker(){

            this.showDatePicker=false;

        },

        selectVisitDate(val){
            this.visitForm.visitTime = dateChange(val);
            this.closeDatePicker();
            this.updateRecordData();
        },

        //下拉加载

        onRefresh(){

            this.page=1;

            this.finished=true;

            this.getRecordList('refresh');

        },

        //搜索

        onSearch(){

            this.page=1;

            this.$toast.loading({

                message: '正在加载...',
                forbidClick: true,
                duration:0

            })

            this.getRecordList('search');

        },

        //上拉更多

        onLoad(){

            this.getRecordList();

        },

        //获取列表

        getRecordList(type){

            let _options = {

                page:this.page,

                pageSize:this.size,

                keyword:this.keyword,

                approvalStatus:this.flowPathValue,

                areaId:this.areaId

            }

            //添加搜索时间参数

            if(this.recordTimeIndex > 0){

                //只有自选的时候才使用该参数

                if(this.recordTimeIndex == 6 && this.recordStartTime && this.recordEndTime){

                    _options.reportDate = [this.recordStartTime,this.recordEndTime];


                }else{

                    _options.reportDate = [this.recordTimeObj.startDate,this.recordTimeObj.endDate];

                }


            }

            api.getChannelRecordList({

                options:_options,

                success:(res)=>{

                    if(res.type == 200){

                        this.listError=false;
                        let last = this.$listLastCheck(res.data.list,this.size);
                        if(!last){

                            this.page++;

                        }

                        this.finished=last;

                        //当第一次加载且为下拉或搜索时,进行数据处理

                        if(this.recordList.length==0 || type =='refresh' || type =='search'){

                            //处理

                            this.recordStatusOptions= res.data.recordType.map((el)=>{

                                return {text:`${el.label}(${el.number || 0})`,value:el.value}

                            });

                        }


                        //首先判断是否存在勾选状态的楼盘

                        if(type == 'refresh' || type == 'search'){

                            this.$refs.recordListScrollView.scrollTop=0;

                            this.recordList = res.data.list;

                            this.$toast.clear();

                            this.refreshing=false;

                        }else{

                            this.recordList.push(...res.data.list);

                        }

                    }else{

                        this.listError = true;

                        if(type == 'refresh' || type == 'search'){

                            this.$toast.fail(res.msg || '订单数据获取失败');

                        }

                    }


                },

                error:(err)=>{

                    console.log(err);

                    this.listError=true;

                    this.$toast.fail('报备列表获取异常');

                },

                complete:(res)=> {

                    if(type == 'refresh'){

                        this.refreshing=false;

                    }

                    if(res?.code && res.code !=200){

                        this.listError = true;
                        
                    }
                    

                    this.loading=false;

                    this.$store.commit('setRouterData',{key:"recordListRefresh",value:0});
                    
                }


            })


        },

        
        //打开日历

        calendarOpen(){

            this.calendarShow=true;

        },

        //选择日历区间

        selectDateInterval(val){

            this.recordStartTime = dateChange(val[0],'date');

            this.recordEndTime = dateChange(val[1],'date');

            this.$nextTick(()=>{

                this.calendarShow = false;

            })

        },

        //选择地区

        selectCity(data){

            console.log(data);

            //获取上级元素

            let _parent = this.cityList[this.areaIndex];

            //一致，确认是不限

            if(_parent.id == data.id){

                this.areaTitle = _parent.text;

            }else{

                this.areaTitle = data.text;

            }

            if(data.id != this.oldAreaId){

                this.$nextTick(()=>{

                    this.onSearch();

                })

            }
  
            this.$refs.listCityMenu.toggle(false);
            
            this.oldAreaId=data.id;

        },

        selectCityNav(index){

            let item = this.cityList[index];

            if(item.children && item.children.length>0) return;

            this.areaId = item.id;

            this.areaTitle=item.text;

            this.oldAreaId = item.id;

            this.$refs.listCityMenu.toggle(false);

            this.$nextTick(()=>{

                this.onSearch();

            })

        },


        
        //个别数据更新

        recordUpdate(hideToast=false){

            if(!this.$store.state.routerStore.recordListRefresh || this.recordList.length == 0) return;

            api.getRecordAuditData({

                options:{id:this.$store.state.routerStore.recordListRefresh},

                success:(res)=>{

                    if(res.type == 200){

                        //检查是否存在对应的元素

                        let _index = findIndex(this.recordList,(el)=>{

                            return el.id == res.data.id;

                        });
                        
                        if(_index !=-1){

                            this.$set(this.recordList,_index,res.data);

                            if(!hideToast){

                                this.$toast({

                                    position:'bottom',

                                    message:'已更新数据'

                                });

                            }

                        }

                    }

                },

                error:(err)=>{

                    console.log(err);

                },

                complete: ()=> {

                    this.$store.commit('setRouterData',{key:"recordListRefresh",value:0});
                    
                }

            })



        },




        //选择流程下拉菜单

        flowPathChange(data){


            if(data == 0){

                this.flowPathTitle='流程'

            }else{

                this.flowPathTitle=''

            }

            this.$nextTick(()=>{

                this.onSearch();
            })
            

        },


        //选择时间

        selectDate(date){

            //自选

            if(date.value == 99 && (!this.recordStartTime || !this.recordEndTime)) return;

            if(date.value == 0){

                this.recordTimeTitle='时间'

            }else{

                this.recordTimeTitle=date.text;

            }

            
            this.recordTimeIndex = date.value;

            //获取时间区间

            if(date.value !=99 && date.value!=0){

                //拿到区间，转化为字符串

                let inv = getDateFky(date.value);
                
                this.recordTimeObj.startDate= dateChange(inv.start,'date');

                this.recordTimeObj.endDate = dateChange(inv.end,'date');

                this.recordStartTime = this.recordEndTime = '';


            }else{

                this.recordTimeObj.startDate = this.recordStartTime;

                this.recordTimeObj.endDate = this.recordEndTime;

            }

            this.$refs.record_date.toggle(false);
                
            this.$nextTick(()=>{

                this.onSearch();

            })

            
        },
        
        



        //时间区间格式化

        formatter (type, value) {
            
            return pickerFormatter(type,value);

        },

        //修改报备单状态
        submitRecordData(type,typeTxt,item){

            this.$dialog.confirm({

                title:`修改订单状态`,

                message:`确定要将订单状态修改为<span style="color:${type=='VISIT' ? '#03bb03' : 'rgb(223, 112, 39)'}"> ${typeTxt} </span>吗？\n此操作不可逆。`,

                confirmButtonColor:`${type=='VISIT' ? '#03bb03' : '#df7027'}`
                

            }).then(()=>{
                
                this.visitForm = {
                    type,
                    item
                }

                //未到访可以直接提交
                if(type == 'UNVISIT'){
                    this.updateRecordData(type,item);
                }else{
                    //先选择到访时间
                    this.showDatePicker = true;
                }
              
            }).catch(()=>{
                
                this.visitForm = {}
            })

        },

        updateRecordData(){

            if(isObjectNone(this.visitForm)) return;

            let formData = {id:this.visitForm.item.id,approvalStatus:this.visitForm.type};

            if(this.visitForm?.visitTime){

                formData.visitTime = this.visitForm.visitTime;

            }

            this.$toast.loading({

                    message:'提交中...',

                    duration:0,

                    overlay:true

            })

                api.submitRecordAuditData({

                    options:formData,

                    success:(res)=>{

                        if(res.type == 200){

                            this.$toast({
                                message:'订单状态修改成功',
                                position:'bottom'
                            });
                            
                            this.$store.commit('setRouterData',{key:"recordListRefresh",value:formData.id});

                            this.recordUpdate(true);

                            this.visitForm={};

                        }else{
                            
                            this.$dialog.alert({

                                title:'提交失败',

                                message:res.msg || "订单数据提交失败，请稍后重试"

                            });
                               
                            this.visitForm={};

                        }

                    },

                    error:(err)=>{
                        this.visitForm={};

                        console.log(err);

                        this.$dialog.alert({

                            title:'提交失败',

                            message:"订单数据提交异常，请稍后重试"

                        })

                    },

                    complete:()=>{

                        this.$toast.clear();

                    }

                })

        },



        //点击复制

        onCopy(item){
            let estimatedVisitTime = !!item.estimatedVisitTime ? (item.estimatedVisitTime.split(' '))[0] : '暂无';
            let copyMessage = '';

            if(item.yiji){
                copyMessage =`一级公司名称：${item.copyCompanyName || item.subdistributorName}\n带看人员：${item.dkName || item.subdistributorUserName}\n员工电话：${item.dkPhone || item.subdistributorUserPhone}\n客户姓名：${item.customerInfo.name}\n客户电话：${item.customerInfo.phone}\n客户性别：${item.customerInfo.sex == 1 ?'男':'女'}\n到访人数：${item.visitorNum}\n带看项目：${item.houseName}\n到访时间：${estimatedVisitTime}\n指定接待：${item.reception || '暂无'}\n备注：${item.remark || '暂无'}`
            }else{
                copyMessage =`一级公司名称：${item.yijiCompanyName ? item.yijiCompanyName : this.companyName}\n二级公司名称：${item.copyCompanyName || item.subdistributorName}\n带看人员：${item.dkName || item.subdistributorUserName}\n员工电话：${item.dkPhone || item.subdistributorUserPhone}\n客户姓名：${item.customerInfo.name}\n客户电话：${item.customerInfo.phone}\n客户性别：${item.customerInfo.sex == 1 ?'男':'女'}\n到访人数：${item.visitorNum}\n带看项目：${item.houseName}\n到访时间：${estimatedVisitTime}\n指定接待：${item.reception || '暂无'}\n备注：${item.remark || '暂无'}`
            }

            this.$copyText(copyMessage).then(()=>{
                
                this.$toast({

                    message:'报备格式复制成功',

                    position:"bottom"

                });
    
                },()=>{
                
                this.$toast({

                    message:'复制失败，如果多次复制失败，请联系管理员',

                    position:'bottom'

                });

            })

        },




        linkTo(item){
            
            //每次跳转都设置一次跳转内容

            this.scrollTop=this.$refs.recordListScrollView.scrollTop;

            this.$router.push({path:`/record/detail/${item.id}`});

        },


    },


    //滚动状态监听

    activated(){

        //数据处理
        if(this.$store.state.userInfo?.cityList){
            let cityList = JSON.stringify(this.$store.state.userInfo?.cityList);
            cityList = cityList.replace(/\"value\"/g,'"id"');
            this.cityList = JSON.parse(cityList);
        }else{
            this.cityList = [{text:'不限',id:0,children:[]}];
        }

        this.$nextTick(()=>{

                if(this.recordStatusOptions.length == 0){

                    this.recordStatusOptions = this.recordStatusList;

                }

                if(this.scrollTop){

                    this.$refs.recordListScrollView.scrollTop=this.scrollTop;


                }

                //检查是否存在需要更新的数据
   
                this.recordUpdate();

        })

    },


    beforeDestroy(){

        this.$route.meta.scrollTop=0;
        
    }

}
</script>

<style lang="less">

.record-list-wrapper{

    height:100%;
    
    .record-list-cover{

        height:100%;

    }

    .multiple-toolbar-wrapper{

        position: absolute;

        z-index:66;

        bottom:12vh;

        right:30px;
        
        width: 40px;

        .multiple-toolbar-btn{

            width: 50px;

            height:50px;

            background:rgb(0, 160, 228);

            border-radius: 50%;
            
            text-align: center;

            line-height:50px;

            font-size:30px;

            color:#fff;

            opacity: .6;

            margin-bottom:15px;

            transition:opacity .3s;

            &:active{

                opacity: 1;

            }

        }

    }

    .hd{

        .menu{

           .date-interval{

               display: flex;

               padding:15px;

               align-items: center;

               .date-interval-item{

                   font-size:13px;

                   line-height:24px;

                   padding: 2px 8px 1px;

                   background:#f6f6f6;

                   color:#999;

                   &.confirm{

                    margin-left:40px;

                    padding: 2px 15px 1px;

                    background: #eee;

                    border-radius:3px;

                    border: 0;

                    &.live{

                        background: #00a0e4;

                        color:#fff;

                    }

                   }

               }

           }

        }

    }


    .bd{

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y: auto;

        background: #f6f6f6;

        .van-pull-refresh{

            min-height:100%;

        }

        .record-item{

            background: #fff;

            margin-top:10px;

            padding: 10px 15px;

            overflow: hidden;

            position: relative;

            .record-item-multiple-overly{

                position: absolute;

                z-index:10;

                left:0;

                top:0;

                right:0;

                bottom:0;

            }

            &:first-child{

                margin-top:0;

            }

            .record-item-cover{
                
                display: flex;

            }


            .record-item-l{

                margin-top:2px;

            }

            .button-cover{

                button{

                    margin-left:10px;

                    &:first-child{

                        margin-left:0;

                    }

                }

            }

            &.m-selected{

                box-shadow:0 0 8px rgba(0, 160, 228,.5) inset;

            }
            
            .record-item-r{

                flex:1;

                width: 0;

                padding-left:10px;

                .info{

                    padding-bottom:6px;

                    .customer{

                        display: flex;

                        justify-content: space-between;

                        align-items: flex-end;

                    }

                    .name{

                        font-size:16px;

                    }

                    .phone,.record-time{

                        font-size:12px;

                        color:#999;

                    }

                    .record-time.close{

                        position: relative;

                        &:after{

                            content:"";

                            position: absolute;

                            width: 50px;

                            height:50px;

                            background: url('../../assets/images/close.png') scroll no-repeat center center / 100% 100%;

                            right:-15px;

                            top:-20px;

                            transform: rotate(25deg);

                            z-index:10;

                        }

                    }

                    .phone{

                        vertical-align: bottom;

                        padding-left:8px;

                    }
                    
                    .house-name{

                        font-size:14px;

                        line-height:28px;

                        padding-top:3px;

                    }

                    .visit-date{

                        font-size:14px;

                        line-height:28px;

                        padding-top:3px;

                    }

                    .label-item{

                        line-height:24px;

                        font-size:13px;

                        display: flex;
                        
                        span:nth-child(2){

                            flex:1 1 auto;

                            width: 0;

                        }

                    }

                    .staff-info{

                        line-height:24px;

                        font-size:13px;

                        display:flex;

                        align-items: center;

                        a{


                            position: relative;

                            z-index:30;

                            padding-left:5px;

                            .call-icon{

                                color:#00a0e4;

                                font-size:14px;

                                padding-left:2px;

                                position: relative;

                                top:1px;

                                transform: rotate(20deg);

                            }

                        }

                    }

                }

                .des{

                    padding-top:13px;

                    .record-type{

                        display: flex;

                        justify-content: space-between;

                        align-items: center;

                        .record-type-status{

                            font-size:14px;

                            color:#666;

                            &.await{

                                color:rgb(0, 160, 228,.6);

                            }

                            span{

                                &.faild{

                                    color:rgba(252, 54, 54,.6);

                                }

                                &.success{

                                    color:rgb(3, 187, 3,.6);

                                }

                                &.warning{

                                    color:#ff976a;

                                }

                            }

                        }

                        .record-copy{

                            padding: 4px 10px 3px;

                            font-size:12px;

                            color:rgb(3, 187, 3);

                            border: 0;

                            background: transparent;

                            &:after{

                                border-color:rgb(3, 187, 3);

                                border-radius:5px;

                            }

                            &:active{

                                background: rgba(0, 255, 0,.05);

                            }

                        }

                        .record-again{

                            padding: 4px 10px 3px;

                            font-size:12px;

                            color:rgb(223, 112, 39);

                            border: 0;

                            background: transparent;

                            &:after{

                                border-color:rgb(223, 112, 39);

                                border-radius:5px;

                            }

                            &:active{

                                background: rgba(255, 145, 0, 0.05);

                            }

                        }

                    }

                }

            }

            .record-extra{

                padding-top:10px;

                .recordFailed{

                    color:#999;

                    display: flex;

                    align-items: flex-start;

                    span{
                        
                        line-height:18px;

                    }

                    span:nth-child(2){

                        flex:1;

                        width: 0;

                    }

                }

            }

        }
        

    }

    

}

</style>
